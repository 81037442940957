import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';

import useOrder from '../../hooks/useOrder';
import Background from '../../theme/components/Background';
import theme from '../../theme/theme';
import {
  OrderItemType,
  OrderRequestType,
  OrderSourceEnum,
} from '../../types/order';
import { ShopType } from '../../types/shop';
import ShopSelector from '../ShopSelector';

type Props = {
  productsToOrder: OrderItemType[];
  shopList?: ShopType[];
  setEmail: Dispatch<SetStateAction<string>>;
};

type FormType = {
  customer_name: string;
  customer_email: string;
  customer_phone: string;
};

const ShoppingCardOrder = ({ productsToOrder, shopList, setEmail }: Props) => {
  const [selectedShop, setSelectedShop] = useState<ShopType | ''>();
  const { isLoading, handleMakeOrder } = useOrder();

  const onSubmit = (data: FormType) => {
    if (selectedShop) {
      const requestData: OrderRequestType = {
        store_id: selectedShop.id,
        customer_name: data.customer_name,
        customer_email: data.customer_email,
        customer_phone: data.customer_phone,
        order_items: productsToOrder,
        order_source: OrderSourceEnum.web,
      };
      setEmail(data.customer_email);
      handleMakeOrder(requestData);
    } else {
      setSelectedShop(''); // to run validation error
    }
  };

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const nameRegExp = /^[a-ząćęłńóśźż ,.'-]+$/i;

  const validationSchema = Yup.object().shape({
    customer_name: Yup.string().test(
      'validation-check',
      'To nie jest poprawne imię',
      (text) => {
        if (text === '' || text === undefined) return true;
        return nameRegExp.test(text);
      },
    ),
    customer_phone: Yup.string().test(
      'validation-check',
      'To nie jest poprawny nr telefonu',
      (text) => {
        if (text === '' || text === undefined) return true;
        return phoneRegExp.test(text);
      },
    ),
    customer_email: Yup.string()
      .email('Niepoprawny email')
      .required('Email jest wymagany'),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormType>({
    resolver: yupResolver(validationSchema),
  });

  return (
    <Box>
      <Background />

      <Box style={{ justifyContent: 'center', padding: 16 }}>
        <Typography textAlign="center" variant="h6" style={styles.headerText}>
          Wybierz sklep odbioru zamówienia
        </Typography>
        {/* todo: dać komunikat o problemie z pobraniem listy sklepów do wyboru zamówienia */}
        {shopList ? (
          <Box style={styles.shopContainer}>
            <ShopSelector
              shops={shopList}
              onSelect={setSelectedShop}
              error={selectedShop === ''}
            />
          </Box>
        ) : null}
        <Typography textAlign="center" variant="h6" style={styles.headerText}>
          Uzupełnij dane
        </Typography>
        <Box style={styles.formContainer}>
          <form onSubmit={handleSubmit((e) => onSubmit(e))}>
            <Controller
              name="customer_name"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  label="Imię"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  {...field}
                  error={!!errors.customer_name}
                  helperText={errors?.customer_name?.message as string}
                />
              )}
            />

            <Controller
              name="customer_phone"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  label="Nr telefonu"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  {...field}
                  error={!!errors.customer_phone}
                  helperText={errors?.customer_phone?.message as string}
                />
              )}
            />
            <Controller
              name="customer_email"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  label="Email"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  {...field}
                  error={!!errors.customer_email}
                  helperText={errors?.customer_email?.message as string}
                />
              )}
            />
            <Box style={styles.buttonContainer}>
              <Button
                type="submit"
                style={{ minWidth: 200 }}
                variant="contained"
                color="primary"
                disabled={isLoading}
              >
                {isLoading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  'Złóż zamówienie'
                )}
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  headerText: {
    fontSize: 18,
    fontWeight: 'bold',
    padding: 8,
    paddingTop: 16,
    color: theme.palette.text.primary,
  },
  betweenButtonsText: {
    padding: 8,
  },
  actionContainer: {
    borderTopStyle: 'solid',
    borderTopWidth: 2,
    borderTopColor: 'black',
    padding: 16,
  },
  infoText: {
    marginTop: 8,
    fontStyle: 'italic',
  },
  buttonContainer: {
    paddingTop: 16,
    display: 'flex',
    justifyContent: 'center',
  },
  formContainer: {
    marginLeft: 32,
    marginRight: 32,
  },
  shopContainer: {
    marginLeft: 32,
    marginRight: 32,
  },
};

export default ShoppingCardOrder;
