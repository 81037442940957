/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { ProductPhotoType } from '../../types/product';

export type ProductCardType = {
  id: number;
  uuid: string;
  name: string;
  price: number;
  size: number;
  image: ProductPhotoType;
};

export type ShoppingCardSliceStateType = {
  selectedProducts: ProductCardType[];
  isVisibleShoppingCard: boolean;
  isExpandShoppingCard: boolean;
};

const initialState: ShoppingCardSliceStateType = {
  selectedProducts: [],
  isVisibleShoppingCard: false,
  isExpandShoppingCard: false,
};

const shoppingCardSlice = createSlice({
  name: 'shoppingCard',
  initialState,
  reducers: {
    addProductToCard: (state, action: PayloadAction<ProductCardType>) => {
      if (state.selectedProducts.length > 2) {
        toast.error('Możesz zamówić maksymalnie 3 produkty');
        return;
      }

      const sameProductId = state.selectedProducts.filter(
        (product) => product.id === action.payload.id,
      );
      const isDuplicatedProduct = sameProductId.some(
        (product) => product.size === action.payload.size,
      );

      if (isDuplicatedProduct) {
        toast.error('Produkt w tym rozmiarze znajduje się już w koszyku');
        return;
      }

      toast.success('Dodano produkt do koszyka', { autoClose: 3000 });
      state.selectedProducts = [...state.selectedProducts, action.payload];
    },
    deleteProductFromCard: (state, action: PayloadAction<string>) => {
      toast.info('Usunięto produkt z koszyka', { autoClose: 3000 });
      state.selectedProducts = state.selectedProducts.filter(
        (product) => product.uuid !== action.payload,
      );
    },
    clearShoppingCard: (state) => {
      toast.info('Wyczyszczono koszyk', { autoClose: 3000 });
      state.selectedProducts = initialState.selectedProducts;
      state.isExpandShoppingCard = false;
    },
    toggleShoppingCard: (state) => {
      state.isVisibleShoppingCard = !state.isVisibleShoppingCard;
    },
    expandShoppingCard: (state, action: PayloadAction<boolean>) => {
      state.isExpandShoppingCard = action.payload;
    },
    closeShoppingCard: (state) => {
      state.isExpandShoppingCard = false;
      state.isVisibleShoppingCard = false;
    },
  },
});

export const {
  addProductToCard,
  deleteProductFromCard,
  clearShoppingCard,
  toggleShoppingCard,
  expandShoppingCard,
  closeShoppingCard,
} = shoppingCardSlice.actions;
export default shoppingCardSlice.reducer;
