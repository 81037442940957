import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Typography } from '@mui/material';
import React, { useCallback } from 'react';

import {
  expandShoppingCard,
  toggleShoppingCard,
} from '../../store/reducers/shoppingCard';
import { useAppDispatch } from '../../store/store';
import theme from '../../theme/theme';

type Props = {
  expand?: boolean;
};

const ShoppingCardHeader = ({ expand }: Props) => {
  const dispatch = useAppDispatch();

  const handleClose = useCallback(() => {
    dispatch(expandShoppingCard(false));
    if (expand) {
      setTimeout(() => {
        dispatch(toggleShoppingCard());
      }, 500);
    } else dispatch(toggleShoppingCard());
  }, [dispatch, expand]);
  return (
    <Box flexDirection="row" style={styles.headerContainer}>
      <Typography
        style={styles.headerText}
        gutterBottom
        color={theme.palette.text.primary}
      >
        Twój Koszyk
      </Typography>
      <IconButton onClick={handleClose} style={styles.closeButton}>
        <CloseIcon />
      </IconButton>
    </Box>
  );
};

const styles = {
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
  },
  headerText: {
    fontSize: 18,
    fontWeight: 'bold',
    padding: 8,
    paddingTop: 16,
  },
  closeButton: {
    width: 52,
    height: 52,
  },
};

export default ShoppingCardHeader;
