import { createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: 'rgba(179,83,27,255)',
    },
    secondary: {
      main: 'rgba(26,93,38,255)',
    },
    text: {
      primary: 'rgba(76,77,92,1)',
    },
  },
});

export default theme;
