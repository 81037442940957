import { ThemeProvider } from '@mui/material';
import * as React from 'react';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import LoadingScreen from './components/LoadingScreen';
import Main from './components/Providers/Main';
import ReactQueryClientProvider from './components/Providers/ReactQueryProvider';
import i18n from './config/translations/i18n';
import store, { persistor } from './store/store';
import theme from './theme/theme';
import routes from './routes';

const App = () => {
  const AppNavigation = useRoutes(routes);

  return (
    <Provider store={store}>
      <PersistGate loading={<LoadingScreen />} persistor={persistor}>
        <ReactQueryClientProvider>
          <ThemeProvider theme={theme}>
            <I18nextProvider i18n={i18n}>
              <Main>{AppNavigation}</Main>
            </I18nextProvider>
          </ThemeProvider>
        </ReactQueryClientProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
