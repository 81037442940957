import { Box, Button, List, Typography } from '@mui/material';
import React, { useCallback } from 'react';

import { emptyImage } from '../../assets/images';
import {
  deleteProductFromCard,
  ProductCardType,
} from '../../store/reducers/shoppingCard';
import { useAppDispatch } from '../../store/store';

type Props = {
  data: ProductCardType[];
  isSummaryView?: boolean;
};

const ShoppingCardList = ({ data, isSummaryView }: Props) => {
  const dispatch = useAppDispatch();
  const itemsCount = data.length;

  const renderItem = useCallback(
    (item: ProductCardType, index: number) => {
      const isLast = itemsCount === index + 1;
      return (
        <Box
          key={item.id}
          borderBottom={isLast ? 'none' : 'solid'}
          style={styles.itemContainer}
        >
          <Box
            // TODO: navigate to product details by id, add when EP will be ready
            onClick={() => {}}
            key={item.uuid}
            component="img"
            src={item?.image?.url || emptyImage}
            style={styles.itemPhoto}
          />
          <Box style={styles.descriptionContainer}>
            <Box style={{ padding: 4, paddingLeft: 16 }}>
              <Typography style={styles.titleText}>{item.name}</Typography>
              <Typography
                style={styles.priceText}
              >{`${item.price} zł`}</Typography>
              <Typography
                style={styles.sizeText}
              >{`rozmiar: ${item.size}`}</Typography>
            </Box>
            {!isSummaryView && (
              <Box style={styles.deleteButtonContainer}>
                <Button
                  size="small"
                  style={styles.deleteButton}
                  variant="text"
                  onClick={() => dispatch(deleteProductFromCard(item.uuid))}
                >
                  Usuń produkt
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      );
    },
    [dispatch, isSummaryView, itemsCount],
  );

  return <List>{data.map((item, index) => renderItem(item, index))}</List>;
};

const styles = {
  itemContainer: {
    borderColor: 'silver',
    borderWidth: 1,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    marginBottom: 4,
    paddingBottom: 4,
    paddingRight: 16,
  },
  itemPhoto: {
    height: 150,
    width: 'auto',
    maxWidth: 150,
  },
  titleText: {},
  priceText: { fontWeight: 'bold', color: 'red' },
  sizeText: { color: 'silver' },
  descriptionContainer: {},
  deleteButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  deleteButton: {
    color: 'silver',
  },
};

export default ShoppingCardList;
