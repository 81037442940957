import { Box, useTheme } from '@mui/material';
import * as React from 'react';

import { backgroundImage } from '../../assets/images';

type PropsType = {
  withImage?: string;
};

const Background = ({ withImage }: PropsType) => {
  const theme = useTheme();

  const styles = {
    container: {
      display: 'flex',
      flexDirection: { xs: 'column', md: 'row' },
      position: 'fixed',
      zIndex: -1,
    },
    background: {
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundImage: `url(${backgroundImage})`,
      width: '100vw',
      height: '100vh',
      [theme.breakpoints.up('md')]: {
        backgroundSize: 'cover',
      },
      [theme.breakpoints.down('md')]: {
        backgroundSize: 'contain',
      },
    },
    imageContainer: {
      display: 'flex',
      position: 'absolute',
      right: 0,
    },
    image: {
      backgroundPosition: 'right',
      height: '100vh',
      backgroundSize: 'cover',
    },
  };

  return (
    <Box sx={styles.container}>
      <Box style={styles.background} component="img" src={backgroundImage} />
      {!!withImage && (
        <Box sx={styles.imageContainer}>
          <Box style={styles.image} component="img" src={withImage} />
        </Box>
      )}
    </Box>
  );
};

export default Background;
