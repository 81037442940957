import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import useShopList from '../../hooks/useShopList';
import { useAppSelector } from '../../store/store';
import { ShopType } from '../../types/shop';
import ShoppingCardActionButtons from './ShoppingCardActionButtons';
import ShoppingCardHeader from './ShoppingCardHeader';
import ShoppingCardList from './ShoppingCardList';
import ShoppingCardOrder from './ShoppingCardOrder';
import ShoppingCardSuccessOrder from './ShoppingCardSuccessOrder';

const drawerWidth = 350;
const drawerExpand = '100%';

const CardContainer = styled('div')(
  ({ theme }) => `
    background-color: white;
    border-radius: 20;
    position: fixed;
    box-shadow: -3px 0px 10px 1px #aaaaaa;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: ${drawerExpand};
    z-index: 100;
    transition: ${theme.transitions.create(['width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    })};
`,
);

const ShoppingCard = () => {
  const items = useAppSelector((state) => state.shoppingCard.selectedProducts);
  const visible = useAppSelector(
    (state) => state.shoppingCard.isVisibleShoppingCard,
  );
  const isOrderSuccess = useAppSelector((state) => state.view.showOrderSuccess);
  const expand = useAppSelector(
    (state) => state.shoppingCard.isExpandShoppingCard,
  );

  const [email, setEmail] = useState<string>('');

  const { shopList } = useShopList();

  const productsToOrder = useMemo(
    () => items.map((item) => ({ product_id: item.id, size: item.size })),
    [items],
  );

  const [expandVisibleContent, setExpandVisibleContent] =
    useState<boolean>(expand);
  const total = items.reduce((acc, item) => acc + item.price, 0).toFixed(2);

  const getWidth = useCallback(() => {
    if (expand) return drawerExpand;
    if (visible) return drawerWidth;
    return 0;
  }, [expand, visible]);

  useEffect(() => {
    setTimeout(() => setExpandVisibleContent(expand), 100);
  }, [expand]);

  if (isOrderSuccess)
    return <ShoppingCardSuccessOrder orderedProducts={items} email={email} />;

  return (
    <CardContainer
      sx={{
        width: getWidth(),
        overflow: 'auto',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      {expandVisibleContent && (
        <ShoppingCardOrder
          setEmail={setEmail}
          productsToOrder={productsToOrder}
          shopList={shopList?.data as unknown as ShopType[]}
        />
      )}
      <Box style={{ width: drawerWidth }}>
        <Box style={{ padding: 16 }}>
          <ShoppingCardHeader expand={expandVisibleContent} />
          <ShoppingCardList data={items} />
        </Box>
        <ShoppingCardActionButtons
          isEmpty={items.length === 0}
          total={total}
          expand={expandVisibleContent}
        />
      </Box>
    </CardContainer>
  );
};

export default ShoppingCard;
