import { Autocomplete, TextField } from '@mui/material';
import React, { Dispatch, SetStateAction, useState } from 'react';

import { ShopType } from '../types/shop';

export interface ProductSizeSelectorProps {
  shops: ShopType[];
  onSelect: Dispatch<SetStateAction<ShopType | undefined | ''>>;
  error: boolean;
}

const ShopSelector: React.FC<ProductSizeSelectorProps> = ({
  shops,
  onSelect,
  error,
}) => {
  const [selectedShop, setSelectedShop] = useState<ShopType>();

  const handleShopSelect = (event: any, value: ShopType | null) => {
    if (value) {
      setSelectedShop(value);
      onSelect(value);
    } else {
      setSelectedShop(undefined);
      onSelect(undefined);
    }
  };

  return (
    <Autocomplete
      options={shops}
      getOptionLabel={(option) =>
        `${option.city}, ${option.street} ${option.buildingNumber} ${
          option.apartmentNumber && `/${option.apartmentNumber}`
        }`
      }
      value={selectedShop}
      onChange={handleShopSelect}
      noOptionsText="Brak sklepów do wyboru"
      renderInput={(params) => (
        <TextField
          {...params}
          label="Wybierz sklep"
          variant="outlined"
          helperText={error ? 'Wybierz sklep do odbioru zamówienia' : ''}
          error={error}
        />
      )}
    />
  );
};

export default ShopSelector;
